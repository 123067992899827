var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col justify-center items-center py-6 md:py-0 md:px-6 md:flex-row md:gap-16"
  }, [_c('header', {
    staticClass: "flex flex-col items-center gap-4 w-full md:w-[400px]"
  }, [_c('img', {
    staticClass: "header__icon-dietela",
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/new_landing_page/logo-color@3x.webp?updatedAt=1689904664797",
      "alt": "icon logo dietela"
    }
  }), _c('img', {
    staticClass: "header__icon-login",
    attrs: {
      "src": _vm.iconLogin,
      "alt": "icon login dietela"
    }
  })]), _c('main', {
    staticClass: "flex flex-col items-center w-full px-5 md:px-0 md:py-16 md:h-screen md:overflow-auto md:w-[400px] md:scrollbar-hide"
  }, [_c('h1', {
    staticClass: "title"
  }, [_vm._v(" Lengkapi Profilemu ")]), _c('div', {
    staticClass: "flex flex-col w-full"
  }, [_c('FormulateForm', {
    staticClass: "tab-form",
    attrs: {
      "debounce": 50
    },
    on: {
      "submit": _vm.handleSignup
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var isValid = _ref.isValid,
            isLoading = _ref.isLoading;
        return [_c('FormulateInput', {
          attrs: {
            "name": "firstname",
            "label": "Nama Depan",
            "icon-prefix": _vm.iconProfile,
            "icon-prefix-style": {
              marginTop: '-2px'
            },
            "placeholder": "Masukkan nama depan",
            "validation": "required",
            "type": "text"
          }
        }), _c('FormulateInput', {
          attrs: {
            "name": "lastname",
            "label": "Nama Belakang",
            "icon-prefix": _vm.iconProfile,
            "icon-prefix-style": {
              marginTop: '-2px'
            },
            "placeholder": "Masukkan nama belakang",
            "validation": "required",
            "type": "text"
          }
        }), _c('FormulateInput', {
          attrs: {
            "name": "birthdate",
            "label": "Tanggal Lahir",
            "icon-prefix": _vm.iconCalendar,
            "icon-prefix-style": {
              marginTop: '-2px'
            },
            "placeholder": "Masukkan tanggal lahir",
            "validation": "required",
            "type": "date"
          }
        }), _c('FormulateInput', {
          attrs: {
            "name": "country",
            "label": "Negara",
            "icon-prefix": _vm.iconCountry,
            "icon-prefix-style": {
              marginTop: '-2px'
            },
            "placeholder": "Masukkan negara",
            "validation": "required",
            "type": "select",
            "options": _vm.data
          }
        }), _c('FormulateInput', {
          attrs: {
            "name": "phone",
            "label": "Nomor Telepon",
            "icon-prefix": _vm.iconPhone,
            "icon-prefix-style": {
              marginTop: '-2px'
            },
            "placeholder": "Masukkan nomor telepon",
            "validation": "required|number",
            "type": "tel"
          }
        }), _c('div', {
          staticClass: "tab-form-action"
        }, [_c('d-button', {
          attrs: {
            "type": "submit",
            "disabled": !isValid || isLoading
          }
        }, [_vm._v(" Simpan ")])], 1)];
      }
    }]),
    model: {
      value: _vm.formValues,
      callback: function callback($$v) {
        _vm.formValues = $$v;
      },
      expression: "formValues"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }