<template>
  <div class="flex flex-col justify-center items-center py-6 md:py-0 md:px-6 md:flex-row md:gap-16">
    <header class="flex flex-col items-center gap-4 w-full md:w-[400px]">
      <img
        class="header__icon-dietela"
        src="https://ik.imagekit.io/dietela/pwa_webp/new_landing_page/logo-color@3x.webp?updatedAt=1689904664797"
        alt="icon logo dietela"
      >
      <img
        class="header__icon-login"
        :src="iconLogin"
        alt="icon login dietela"
      >
    </header>
    <main class="flex flex-col items-center w-full px-5 md:px-0 md:py-16 md:h-screen md:overflow-auto md:w-[400px] md:scrollbar-hide">
      <h1 class="title">
        Lengkapi Profilemu
      </h1>
      <div class="flex flex-col w-full">
        <FormulateForm
          v-slot="{ isValid, isLoading }"
          v-model="formValues"
          class="tab-form"
          :debounce="50"
          @submit="handleSignup"
        >
          <FormulateInput
            name="firstname"
            label="Nama Depan"
            :icon-prefix="iconProfile"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan nama depan"
            validation="required"
            type="text"
          />
          <FormulateInput
            name="lastname"
            label="Nama Belakang"
            :icon-prefix="iconProfile"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan nama belakang"
            validation="required"
            type="text"
          />
          <FormulateInput
            name="birthdate"
            label="Tanggal Lahir"
            :icon-prefix="iconCalendar"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan tanggal lahir"
            validation="required"
            type="date"
          />
          <FormulateInput
            name="country"
            label="Negara"
            :icon-prefix="iconCountry"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan negara"
            validation="required"
            type="select"
            :options="data"
          />
          <FormulateInput
            name="phone"
            label="Nomor Telepon"
            :icon-prefix="iconPhone"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan nomor telepon"
            validation="required|number"
            type="tel"
          />
          <div class="tab-form-action">
            <d-button
              type="submit"
              :disabled="!isValid || isLoading"
            >
              Simpan
            </d-button>
          </div>
        </FormulateForm>
      </div>
    </main>
    <!--    <ModalEmailVerification v-show="isModalOpen" :isOpen="isModalOpen" :email="formValues.email"/>-->
  </div>
</template>

<script >
import { ref, watch } from '@vue/composition-api';
import iconLogin from '@/assets/ic-login.svg';
import iconProfile from '@/assets/ic-profile.svg';
import iconCalendar from '@/assets/ic-calendar.svg';
import iconCountry from '@/assets/ic-block.svg';
import iconPhone from '@/assets/ic-phone.svg';
import DButton from '@/components/elements/d-button.vue'; // import ModalEmailVerification from '@/components/widgets/modal-email-verification.vue'

import { store, useActions } from '@/stores';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { useQuery } from '@tanstack/vue-query';
import { formatCountry } from '@/utils/format-country';
import { fetchIp } from '@/requests/fetch-ip';
import { useToast } from '@chakra-ui/vue';
import { createVuexHelpers } from 'vue2-helpers';
import posthog from 'posthog-js';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const {
    useMutations
  } = createVuexHelpers();
  const {
    setToken,
    setUser,
    setRefreshToken,
    setIsGoogle
  } = useMutations('auth', ['setToken', 'setUser', 'setRefreshToken', 'setIsGoogle']);
  const toast = useToast();
  const route = useRoute();
  const router = useRouter();
  const {
    updateProfile
  } = useActions('auth', ['updateProfile']);
  const formValues = ref({
    firstname: route.query.firstName,
    lastname: route.query.lastName,
    birthdate: route.query.birthDate,
    country: '',
    phone: '',
    email: route.query.email
  });
  const {
    data
  } = useQuery({
    queryKey: ['todos'],
    queryFn: () => store.getters.axios.get('/v1/general/countries').then(it => {
      return it.data.data.map(data => {
        return {
          value: data.name,
          label: data.name
        };
      });
    })
  });
  const useFetchIp = useQuery({
    queryKey: ['useFetchIp'],
    queryFn: fetchIp
  });
  watch(useFetchIp.data, async newQuestion => {
    formValues.value.country = formatCountry(newQuestion?.country);
  });

  const handleSignup = async _formValues => {
    try {
      const res = await updateProfile({
        firstName: _formValues.firstname,
        lastName: _formValues.lastname,
        birthDate: _formValues.birthdate,
        country: _formValues.country,
        phone: _formValues.phone,
        email: _formValues.email,
        token: route.query.token
      });
      const {
        token,
        refreshToken,
        ...user
      } = res;
      setUser(user);
      setToken(token);
      setRefreshToken(refreshToken);
      setIsGoogle(user.isGoogle); // this.$store.commit('auth/setUser', user)
      // this.$store.commit('auth/setToken', token)
      // this.$store.commit('auth/setRefreshToken', refreshToken)

      toast({
        status: 'success',
        title: 'Success',
        description: 'login sukses',
        duration: 3000
      });
      router.push({
        name: 'client.index'
      });
      posthog.capture('visitor success signup');
    } catch (e) {
      toast({
        status: 'error',
        title: 'Error',
        description: e?.data?.message || 'Error!',
        duration: 3000
      });
    }
  };

  return {
    iconLogin,
    iconProfile,
    iconCalendar,
    iconCountry,
    iconPhone,
    formValues,
    data,
    handleSignup
  };
};

__sfc_main.components = Object.assign({
  DButton
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped>
.header__icon-dietela{
  width: 110px;
}
.header__icon-login{
  width: 60%;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #008C81;
  margin: 16px 0;
}
.tab-form {
  padding: 25px;
  border: 2px solid #008C81;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tab-form-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.tab-form-action__link > a {
  font-size: 12px;
  font-weight: 500;
  color: #008C81;
  cursor: pointer;
}

.formulate-input::v-deep .formulate-input-errors  {
  color: red;
  margin-left: 20px;
  font-size: 14px;
  margin-top: 6px;
}

@media (min-width: 640px) {
  .tab-form {
    height: 100%;
    overflow: auto;

  }

  .tab-form::-webkit-scrollbar {
    display: none;
  }

  .header__icon-login{
    width: 100%;
    max-width: 450px;
  }

  .header__icon-dietela {
    width: 80px;
  }

  .title {
    margin-top: 0;
  }
}

.checkbox::v-deep div {
  font-size: 12px;
}
</style>
