import axios from 'axios'

export const fetchIp = async()=> {
  const res = await axios.get('https://api.country.is')
  return res.data

  // OX-RESPONSE:
  // {
  // "ip": "125.163.143.23",
  // "country": "ID"
  // }
}